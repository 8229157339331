/**
 * 0  服务商禁用状态
 * 1  服务商启用状态
 */
export const enum ServiceStatusEnum {
    Enable = 0,
    Disable = 1,
    // serviceEnableConst: 0,
    // serviceDisableConst: 1,
}
