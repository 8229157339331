import { service } from '@/ajax/request'

export const getStoreBuildTemplateApi = () => (
  service({
    url: '/storebuild/storeBuildTemplate/list',
    method: 'POST',
  })
)

export const batchStoreBuildTemplateApi = (params) => (
    service({
        url: '/storebuild/storeBuildTemplate/batch',
        method: 'POST',
        params
    })
)

export const batchStoreBuildTemplateSeqApi = (params) => (
    service({
        url: '/storebuild/storeBuildTemplate/changeSeq',
        method: 'POST',
        params
    })
)

export const deleteStoreBuildTemplateApi = (id) => (
    service({
        url: `/storebuild/storeBuildTemplate/delete/${id}`,
        method: 'POST',
    })
)

export const storeBuildOverviewListApi = (params) => (
  service({
      url: `/storebuild/storeBuildOverview/list`,
      method: 'POST',
      params,
  })
)

export const storeBuildOverviewOwnerListApi = (storeId) => (
  service({
      url: `/storebuild/storeBuildOverview/listStoreOwner/${storeId}`,
      method: 'POST',
  })
)

export const storeBuildOverviewOwnerAddApi = (params) => (
  service({
      url: `/storebuild/storeBuildOverview/add/${params.storeId}`,
      method: 'POST',
      type: "form",
      params,
  })
)

export const storeBuildOverviewOwnerEditApi = (params) => (
  service({
      url: `/storebuild/storeBuildOverview/saveStoreOwner/${params.storeId}`,
      method: 'POST',
      type: "form",
      params,
  })
)

export const storeBuildOverviewDetailApi = (storeId) => (
  service({
      url: `/storebuild/storeBuildOverview/listStoreBuildStep/${storeId}`,
      method: 'POST',
  })
)

export const storeBuildOverviewDisableApi = (params) => (
  service({
      url: `/storebuild/storeBuildOverview/disable/${params.id}`,
      method: 'POST',
  })
)