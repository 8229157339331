<template>
    <div class="content-box">
      <div class="content-header">
        <el-input
          v-model="queryForm.storeName"
          maxlength="50"
          class="input"
          clearable
          placeholder="请输入门店名称"
        ></el-input>
        <el-button type="plain" class="cotent-btn" @click="handleSearch">搜索</el-button>
        <el-button type="plain" @click="editStorePlan" class="cotent-btn-big" icon="el-icon-plus">添加门店</el-button>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
          v-loading="loading">
          <el-table-column prop="storeName" align="center" width="" label="门店名称">
            <template #default="{ row }">
                <span @click="viewStorePlan(row)" style="color: #409EFF;cursor: pointer;">{{row.storeName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="buildProgress" align="center" label="已完成/总步骤">
          </el-table-column>
          <el-table-column prop="buildStatus" align="center" label="建店状态">
            <template #default="{ row }">
              <div :class="[statusSetting[row.buildStatus]?.class]">
                {{getBuildStatusText(row.buildStatus, row.completeDays)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createBy" align="center" label="创建人">
          </el-table-column>
          <el-table-column prop="createdTime" align="center" label="创建时间">
          </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template #default="{row}">
              <el-button :disabled="row.buildStatus == 3" type="text" @click="editStorePlan(row)">建店负责人</el-button>
              <el-button :disabled="row.buildStatus == 3" type="text" @click="disabledRecord(row)">作废</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>
        
        <!-- 新增编辑门店 -->
        <el-dialog
            :title="storePlanInfo.title"
            :visible.sync="storePlanInfo.visible"
            width="550px"
            :before-close="storePlanInfoClose">
            <el-form label-position="left" label-suffix=":" label-width="100px" :model="formData" :rules="formRules" ref="validateForm">
                <el-form-item v-show="!formData.id" label="门店名称" prop="storeId">
                    <el-select
                        clearable
                        v-model="formData.storeId"
                        class="dialogInput"
                        remote
                        :remote-method="getStoreList"
                        @focus="getStoreList"
                        filterable
                        placeholder="请选择关联门店"
                        :loading="storeSelectLoading"
                    >
                        <el-option
                        v-for="item in allStoreList"
                        :key="item.id"
                        :label="item.storeName"
                        :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="建店负责人" prop="userId">
                    <el-select
                        clearable
                        :disabled="!formData.storeId"
                        v-model="formData.userId"
                        class="dialogInput"
                        placeholder="请选择建店负责人"
                        :loading="userSelectLoading"
                    >
                        <el-option
                        v-for="(item, index) in allUserList"
                        :key="index"
                        :label="item.userName"
                        :value="item.userId"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="formCancel">取 消</el-button>
                <el-button :loading="saveLoading" @click="formSave" type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 进度弹框 -->
        <el-dialog
            :title="planDialogInfo.title"
            :visible.sync="planDialogInfo.visible"
            width="1000px"
            >
            <div class="plan-dialog-content">
                <el-steps class="left-step" direction="vertical" :space="200">
                  <el-step @click.native="viewStepDetail(step)" :class="{'active-step': activeStep == step.id, 'green-line': greenLineFlag(step, index)}" :status="getStepStatus(step)" :title="step.stepName" v-for="(step,index) in buildStoreStepList" :key="step.id">
                    <template #title>
                      <span class="step-title">{{step.stepName}}</span>
                    </template>
                    <template #description>
                      <div style="margin-bottom: 30px">
                        <p class="default-text">{{step.stepOwner || ""}}</p>
                        <p class="default-text">{{step.stepCompleteTime || ""}}</p>
                      </div>
                    </template>
                  </el-step>
                </el-steps>
                <div class="right-content box ql-container ql-snow">
                  <div class="ql-editor"  v-html="planDialogInfo.stepDetail"></div>
                </div>
            </div>
        </el-dialog>
      </div>
    </div>
  </template>
  
  <script>
  import { Watch, Mixins, Component } from "vue-property-decorator";
  import tablePagination from "@/mixins/tablePagination";
  import loading from "@/mixins/loading";
  import Resize from "@/mixins/resize";

  import { storeBuildOverviewListApi, storeBuildOverviewOwnerListApi, storeBuildOverviewOwnerAddApi, storeBuildOverviewOwnerEditApi, storeBuildOverviewDetailApi, storeBuildOverviewDisableApi } from "@/api/buildStore";
  
  import { storeList } from "@/api/store";
  
  // 工具
  import { debounce, cloneDeep, uniqWith } from "lodash";
  
  // 常量
  import { ServiceStatusEnum } from "@/enum/service.enum";
  
  @Component
  export default class BuildStoreList extends Mixins(loading, tablePagination, Resize) {
    serviceStatusEnum = ServiceStatusEnum
  
    queryForm = { // 查询参数
      storeName: ""
    }
  
    tableData = [];
    itemTotal = 0;
    
    formData = {
        id: "",
        storeId: "",
        userId: ""
    };
  
    allStoreList = [];
    storeSelectLoading = false;

    allUserList = [];
    userSelectLoading = false;
  
    saveLoading = false;

    storePlanInfo = {
        visible: false,
        title: "",
        storeId: "",
        userId: ""
    }

    planDialogInfo = {
        visible: false,
        title: "",
        stepDetail: "",
    };
  
  
    formRules = {
        storeId: [{ required: true, message: "请选择门店", trigger: "change" }],
        userId: [{ required: true, message: "请选择建店负责人", trigger: "change" }],
    }

    statusSetting = {
      0: { class: 'wait' },
      1: { class: 'done' },
      2: { class: 'doing' },
      3: { class: 'wait' }
    };

    buildStoreStepList = [];

    activeStep = 0;

    mounted() {
        this.windowResize(310)
        this.getData();
    }

    getBuildStatusText(status, completeDays) {
      if (status == 0) return "未开始"
      if (status == 2) return "进行中"
      if (status == 3) return "已作废"
      if (status == 1) return `已完成（${completeDays}天）`
    }
  
    getData() {
      this.showLoading();
      storeBuildOverviewListApi({
        ...this.queryForm,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      }).then(res => {
        this.hideLoading();
        this.tableData = res.data.list;
        res.data.list?.forEach((el) => {
          el.buildStoreOwner = el.buildStoreOwner || ""
        })
        this.itemTotal = res.data.total;
      }).finally(() => {
        this.hideLoading();
      });
    }
  
    //重置分页参数
    resetPages() {
      this.currentPage = 1;
    }
  
    // 搜索
    handleSearch() {
      this.resetPages();
      this.getData();
    }

    disabledRecord(row) {
      this.$confirm(`作废【${row.storeName}】的建店流程后，小程序将不显示该门店的建店内容，确认作废？`, "", {type: 'warning'}).then(() => {
        storeBuildOverviewDisableApi({ id: row.id }).then(() => {
          this.getData();
        })
      })
    }

    editStorePlan(row) {
        this.storePlanInfo.visible = true;
        this.storePlanInfo.title = "新增门店";
        this.$nextTick(() => {
          const { id, buildStoreOwner, storeId } = row;
          if (row.id) {
              this.storePlanInfo.title = row.storeName;
              this.formData.id = id;
              this.formData.userId = buildStoreOwner ? Number(buildStoreOwner) : "";
              this.formData.storeId = storeId;
          }
        })
    }

    formSave() {
        this.$refs["validateForm"].validate().then(() => {
          this.saveLoading = true;
          const api = this.formData.id ? storeBuildOverviewOwnerEditApi : storeBuildOverviewOwnerAddApi;
          api(this.formData).then((res) => {
            this.$message.success("操作成功")
            this.formCancel();
            this.getData();
          }).finally(() => {
            this.saveLoading = false;
          })
        })
    }
  
    formCancel() {
        this.storePlanInfoClose();
    }

    storePlanInfoClose() {
        this.storePlanInfo.visible = false;
        this.$refs["validateForm"].resetFields()
        this.formData.id = "";
    }

    getOverviewOwnerList(storeId) {
      storeBuildOverviewOwnerListApi(storeId).then((res) => {
        this.allUserList = res.data;
      })
    }

    getStoreRemote = debounce(function (str) {
        this.storeSelectLoading = true;
        storeList({storeName: str,pageNum: 1,pageSize: 500}).then((res) => {
          this.storeSelectLoading = false;
          this.allStoreList = res.data && res.data.list;
        }).catch((err) => {});
    }, 800)
  
  
    getStoreList(str) {
      if (typeof str == "object") {
        str = "";
      }
      this.getStoreRemote(str)
    }

    viewStorePlan(row) {
        this.planDialogInfo.visible = true;
        this.planDialogInfo.title = row.storeName;
        this.planDialogInfo.stepDetail = "";
        storeBuildOverviewDetailApi(row.storeId).then((res) => {
          this.buildStoreStepList = res.data || [];
          // 默认显示第一条
          this.viewStepDetail(this.buildStoreStepList[0]);
        })
    }

    viewStepDetail(step) {
      this.planDialogInfo.stepDetail = step.stepDetail;
      this.activeStep = step.id;
    }

    getStepStatus(step, index) {
      const { completeStatus } = step;
      if (completeStatus)  return 'success';
      if(this.activeStep == step.id) return 'process';
      return 'wait';
    }

    greenLineFlag(step, index) {
      const nextStep = this.buildStoreStepList[index + 1] || {};
      return step.completeStatus == 1 && step.completeStatus == nextStep.completeStatus;
    }

    @Watch("formData.storeId")
    getOwnerOptions(storeId) {
      if (!storeId) return;
      if (!this.formData.id) {
        this.formData.userId = "";
      } 
      this.getOverviewOwnerList(storeId);
    }
  }
  </script>
  
<style lang="scss" scoped>
@import "@/styles/app";

.content-box {
  background: #edf0f9;
}

.content-header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dfe2e4;
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
  display: flex;
  align-items: center;
}

.input {
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 12px;
}
.cotent-btn {
  margin-left: 12px;
  border: 1px solid #0054ff;
}

.cotent-btn-big {
  margin-left: 40px;
  width: 300px;
  border: 1px dashed #0054ff;
}

.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 101px);
}

.dialogInput {
  width: 100%;
}

.dialog-footer{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.store-saas-name{
  color: $primary;
  margin-left: 5px;
}
.plan-dialog-content{
    max-height: calc(100vh - 280px);
    overflow: hidden;
    display: flex;
    .left-step{
        width: 300px;
        height: 100%;
        height: calc(100vh - 280px);
        overflow-y: auto;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        margin-right: 10px;
    }
    .right-content{
        flex: 1;
        word-wrap: break-word;
        word-break: break-all;
        height: calc(100vh - 280px);
        overflow-y: auto;
        border-radius: 4px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }
}
.tag{
    margin-right: 5px;
}
:deep(.is-success){
  color: #409EFF;
  .el-step__icon{
    border-color: #409EFF !important;
  }
}

.ql-container.ql-snow{
    // border: none;
}
.green-line {
  :deep(.el-step__line){
    background-color: #409EFF!important;
  }
}
:deep(.el-dialog__body) {
  padding-top: 10px;
}
.default-text{
  color: #999
}
.done{
    color: #529DF1;
  }
  .wait{
    color: #A3ACB4;
  }
  .doing{
    color: #1FAA56;
  }
</style>