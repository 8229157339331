import { service } from "@/ajax/request";

// 获取门店列表
export const storeList = (params: any) =>
  service({
    url: "/store/storeDevice/list",
    method: "POST",
    params,
    type: "form",
  });

export const createStore = (params: any) =>
  service({
    url: "/store/storeDevice/saveAndUserOwner",
    method: "POST",
    params,
  });

export const editStore = (params: any) =>
  service({
    url: "/store/storeDevice/updateStoreAndUserOwner",
    method: "POST",
    params,
  });

export const allStoreList = (params: any) =>
  service({
    url: "/store/storeDevice/allList",
    method: "GET",
    params,
    type: "form",
  });

export const detectionDeviceDeadline = (params: any) =>
  service({
    url: "/store/data/detectionDeviceDeadline",
    method: "GET",
    params,
    type: "form",
  });

export const healthDeviceDeadline = (params: any) =>
  service({
    url: "/store/data/healthDeviceDeadline",
    method: "GET",
    params,
    type: "form",
  });

export const userDataCount = (params: any) =>
  service({
    url: `/store/data/userDataCount/${params.type}`,
    method: "GET",
    params,
    type: "form",
  });

export const userDetectionCount = (params: any) =>
  service({
    url: `/store/data/userDetectionCount/${params.type}`,
    method: "GET",
    params,
    type: "form",
  });

export const userHealthCount = (params: any) =>
  service({
    url: `/store/data/userHealthCount/${params.type}`,
    method: "GET",
    params,
    type: "form",
  });

  
export const healthTotalCount = (params: any) =>
service({
  url: "/store/data/healthTotalCount",
  method: "GET",
  params,
  type: "form",
});


// 获取saas门店列表
export const getSaasStoreList = (params: any) => {
  return service({
    url: "/saas/saasStore/allSaaSList",
    method: "GET",
    params,
    type: "form"
  })
}

export const getUserBusinessCount = (params) => {
  return service({
    url: `/store/data/userBusinessCount/${params.type}`,
    method: "GET",
    params,
    type: "form"
  })
}

export const getDeviceLoadWithStatus = (params) => {
  return service({
    url: `/device/deviceManage/loadWithStatus`,
    method: "POST",
    params
  })
}

export const updateWxMiniUrl = (storeId) => {
  return service({
    url: `/store/storeDevice/updateWxMiniUrl/${storeId}`,
    method: "POST",
  })
}

export const authBanding = (params) => {
  return service({
    url: `/store/data/authBanding`,
    method: "GET",
    params,
    type: "form"
  })
}

export const bindingByStoreBindingNo = (params: any) => {
  return service({
    url: `/store/storeDevice/bindingByStoreBindingNo`,
    method: "POST",
    params,
  })
}